<header class="product-header" fxLayout="row" fxLayoutAlign="space-around center" fxLayout.lt-sm="column">
  <h1 i18n class="header-title">Channel Manager <br /> & PMS in one</h1>
  <img src="/images/suiteclerk/calendar-pms.png"/>
</header>
<section class="product-section">

  <article class="features" fxLayout="row wrap" fxLayoutAlign="center flex-start">

    <div class="feature" fxLayout="row" fxLayoutAlign="space-between flex-start">
      <mat-icon>check_circle_outline</mat-icon>

      <div fxLayout="column" fxLayoutAlign="center flex-start">
        <h1 i18n>One-Click Connection</h1>
        <p i18n>
          One-click connection to all leading OTAs: Expedia and Hotels.com, LateRooms and Super Break, Booking.com and Agoda, Google Hotel Ads, Airbnb,  HomeAway  Ryanair Rooms, and many more.
        </p>
      </div>
    </div>

    <div class="feature" fxLayout="row" fxLayoutAlign="space-between flex-start">
      <mat-icon>check_circle_outline</mat-icon>

      <div fxLayout="column" fxLayoutAlign="center flex-start">
        <h1 i18n>No Overbookings</h1>
        <p i18n>
          List all of your rooms and availability on all OTAs at the same time – no more double bookings or overbookings!
        </p>
      </div>
    </div>

    <div class="feature" fxLayout="row" fxLayoutAlign="space-between flex-start">
      <mat-icon>check_circle_outline</mat-icon>

      <div fxLayout="column" fxLayoutAlign="center flex-start">
        <h1 i18n>Full 2-Way Synchronization</h1>
        <p i18n>
          Rates, availability, photos, content and policies are instantly updated in real time across all OTAs you choose to work with.
        </p>
      </div>
    </div>

    <div class="feature" fxLayout="row" fxLayoutAlign="space-between flex-start">
      <mat-icon>check_circle_outline</mat-icon>

      <div fxLayout="column" fxLayoutAlign="center flex-start">
        <h1 i18n>Simple Management</h1>
        <p i18n>
          Learn how to handle one OTA travel agency and you’ll know how to manage and connect them all.
        </p>
      </div>
    </div>

    <div class="feature" fxLayout="row" fxLayoutAlign="space-between flex-start">
      <mat-icon>check_circle_outline</mat-icon>

      <div fxLayout="column" fxLayoutAlign="center flex-start">
        <h1 i18n>Keep Full Control</h1>
        <p i18n>
          Spot and manage OTA exceptions at a glance.  No shows and card declines are instantly reported, so you’re never left out of pocket.
        </p>
      </div>
    </div>

    <div class="feature" fxLayout="row" fxLayoutAlign="space-between flex-start">
      <mat-icon>check_circle_outline</mat-icon>

      <div fxLayout="column" fxLayoutAlign="center flex-start">
        <h1 i18n>Access Over 100 Regional Community Sites</h1>
        <p i18n>
          We provide connectivity to a wide array of regional community sites.  Most are commission free, or charge very low commission rates.
        </p>
      </div>
    </div>

  </article>

</section>
