import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intelligent-pricing',
  templateUrl: './intelligent-pricing.component.html',
  styleUrls: ['./intelligent-pricing.component.css']
})
export class IntelligentPricingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
