<header class="product-header" fxLayout="row" fxLayoutAlign="space-around center" fxLayout.lt-sm="column">
  <h1 i18n>Booking Button</h1>
  <img src="/images/suiteclerk/booking-btn.png"/>
</header>
<section class="product-section">

  <article class="features" fxLayout="row wrap" fxLayoutAlign="center flex-start">

    <div class="feature" fxLayout="row" fxLayoutAlign="space-between flex-start">
      <mat-icon>check_circle_outline</mat-icon>

      <div fxLayout="column" fxLayoutAlign="center flex-start">
        <h1 i18n>Global</h1>
        <p i18n>
          Multi-lingual, multi-currency options, to target visitors and accept payments from anywhere in the world.
        </p>
      </div>
    </div>

    <div class="feature" fxLayout="row" fxLayoutAlign="space-between flex-start">
      <mat-icon>check_circle_outline</mat-icon>

      <div fxLayout="column" fxLayoutAlign="center flex-start">
        <h1 i18n>Commission-Free</h1>
        <p i18n>
          All bookings are commission-free and fly directly into your PMS.
        </p>
      </div>
    </div>

    <div class="feature" fxLayout="row" fxLayoutAlign="space-between flex-start">
      <mat-icon>check_circle_outline</mat-icon>

      <div fxLayout="column" fxLayoutAlign="center flex-start">
        <h1 i18n>Exclusive</h1>
        <p i18n>
          Create special offers exclusive to your website.  Promote the best ratings, accreditations and reviews.
        </p>
      </div>
    </div>

    <div class="feature" fxLayout="row" fxLayoutAlign="space-between flex-start">
      <mat-icon>check_circle_outline</mat-icon>

      <div fxLayout="column" fxLayoutAlign="center flex-start">
        <h1 i18n>Full Control</h1>
        <p i18n>
          Change images, text, descriptions and photos at your leisure.  No dependency on engineers, designers or agencies.
        </p>
      </div>
    </div>

    <div class="feature" fxLayout="row" fxLayoutAlign="space-between flex-start">
      <mat-icon>check_circle_outline</mat-icon>

      <div fxLayout="column" fxLayoutAlign="center flex-start">
        <h1 i18n>Safe & Secure</h1>
        <p i18n>
          Free PCI compliant card storage and retrieval. Automatic validation via 3D secure.
        </p>
      </div>
    </div>

    <div class="feature" fxLayout="row" fxLayoutAlign="space-between flex-start">
      <mat-icon>check_circle_outline</mat-icon>

      <div fxLayout="column" fxLayoutAlign="center flex-start">
        <h1 i18n>Convenient</h1>
        <p i18n>
          Provide your guests a quick and smooth online booking experience, optimised for high conversion rates.
        </p>
      </div>
    </div>

  </article>

</section>
