<header class="product-header" fxLayout="row" fxLayoutAlign="space-around center" fxLayout.lt-sm="column">
  <h1 i18n>Intelligent Pricing</h1>
  <img src="/images/suiteclerk/intelligent-pricing.png"/>
</header>
<section class="product-section">

  <article class="features" fxLayout="row wrap" fxLayoutAlign="center flex-start">

    <div class="feature" fxLayout="row" fxLayoutAlign="space-between flex-start">
      <mat-icon>check_circle_outline</mat-icon>

      <div fxLayout="column" fxLayoutAlign="center flex-start">
        <h1 i18n>Increase your revenue</h1>
        <p i18n>
          Make smarter and more confident pricing and distribution decisions to optimise your revenue and maximise occupancy.
        </p>
      </div>
    </div>

    <div class="feature" fxLayout="row" fxLayoutAlign="space-between flex-start">
      <mat-icon>check_circle_outline</mat-icon>

      <div fxLayout="column" fxLayoutAlign="center flex-start">
        <h1 i18n>Automated price strategies</h1>
        <p i18n>
          Increase revenues, with rules & constraints.
        </p>
      </div>
    </div>

    <div class="feature" fxLayout="row" fxLayoutAlign="space-between flex-start">
      <mat-icon>check_circle_outline</mat-icon>

      <div fxLayout="column" fxLayoutAlign="center flex-start">
        <h1 i18n>Automated online invoicing</h1>
        <p i18n>
          Customize templates that instantly generate after each payment.
        </p>
      </div>
    </div>

    <div class="feature" fxLayout="row" fxLayoutAlign="space-between flex-start">
      <mat-icon>check_circle_outline</mat-icon>

      <div fxLayout="column" fxLayoutAlign="center flex-start">
        <h1 i18n>Watch your competitors</h1>
        <p i18n>
          Track the market and produce automatic decisive actions to protect your revenue.
        </p>
      </div>
    </div>

    <div class="feature" fxLayout="row" fxLayoutAlign="space-between flex-start">
      <mat-icon>check_circle_outline</mat-icon>

      <div fxLayout="column" fxLayoutAlign="center flex-start">
        <h1 i18n>Demand control</h1>
        <p i18n>
          Have the foresight to optimise your demand peaks and drive more business in the low season.
        </p>
      </div>
    </div>

    <div class="feature" fxLayout="row" fxLayoutAlign="space-between flex-start">
      <mat-icon>check_circle_outline</mat-icon>

      <div fxLayout="column" fxLayoutAlign="center flex-start">
        <h1 i18n>Channels performance</h1>
        <p i18n>
          Learn which booking channels are working the hardest for your property and bring the most lucrative guests.
      </div>
    </div>

  </article>

</section>
