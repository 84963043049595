// redsys typings
declare var getInSiteForm:any;
declare var getCardInput:any;
declare var getExpirationMonthInput:any;
declare var getExpirationYearInput:any;
declare var getCVVInput:any;
declare var getPayButton:any;
declare var storeIdOper:any;

import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { REDSYS_ERRORS } from "../constants/redsys-errors";
import { RedsysService } from "../redsys.service";
@Component({
  selector: 'app-redsys',
  templateUrl: './redsys.component.html',
  styleUrls: ['./redsys.component.css']
})
export class RedsysComponent implements OnInit {
  
  @HostListener('window:message', ['$event'])
  receiveMessage(event) {
    console.log(event)
    if (event.origin.includes("redsys") && event.data == "merchantValidation") {
      this.errorMessage = null;
      this.loading = true;
      storeIdOper(event, "token", "errorCode", () => {
        if (!this.disabled) return true;

        this.submittedDisabled.emit(true)
      });
    } else if (event.origin.includes("redsys") && event.data.error) {
      this.loading = false;
      this.showError(event.data.error);
    } else if (event.origin.includes("redsys") && event.data.idOper) {
      this.loading = false;
      this.submittedSuccess.emit({ idOper: event.data.idOper, merchantOrder: this.merchantOrder });
    }
  }

  @Output() submittedSuccess: EventEmitter<{ idOper: string, merchantOrder: string }> = new EventEmitter();
  @Output() submittedDisabled: EventEmitter<boolean> = new EventEmitter();
  @Input() disabled: boolean = false;
  @Input() amount: number;
  @Input() merchantOrder: string = (Math.random()*10000).toFixed(0);

  estiloBoton = "width: 100%; margin-top: 1em; color: white; background-color: #008489";
  estiloBody = "";
  estiloCaja = "";
  estiloInputs = "";

  loading: boolean = false;
  errorMessage: string = "";

  constructor(private redsysService: RedsysService) { }

  ngOnInit(): void {
    const redsysInfo = {
      merchantCode: "353971252",
      terminal: "001",
      merchantCurrency: "978"
    };
  
    // iframe loading
    getInSiteForm('card-form', this.estiloBoton, this.estiloBody, this.estiloCaja, this.estiloInputs, 'Submit Payment', redsysInfo.merchantCode, redsysInfo.terminal, this.merchantOrder, 'EN', false);
  }

  showError(errorCode: string) {
    this.errorMessage = REDSYS_ERRORS[errorCode];
  }
  
}
