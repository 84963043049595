<header class="faq-header" fxLayout="column" fxLayoutAlign="center center">
  <h1 i18n>Frequently Asked Questions</h1>
</header>
<section>

  <mat-card class="faq-card mat-elevation-z8">
    <mat-card-header>
      <h1 i18n>What type of properties do we help?</h1>
    </mat-card-header>

    <mat-card-content>
      <p i18n>
        We have flexible features to serve not just hotels, but also hostels, guesthouses and B&Bs.
      </p>
    </mat-card-content>
  </mat-card>

  <mat-card class="faq-card mat-elevation-z8">
    <mat-card-header>
      <h1 i18n>Which languages do we support?</h1>
    </mat-card-header>

    <mat-card-content>
      <p i18n>
        Our interface is available in English, Spanish, and Portuguese. You can communicate with our team in English, Spanish, Portuguese or Polish.
      </p>
    </mat-card-content>
  </mat-card>

  <mat-card class="faq-card mat-elevation-z8">
    <mat-card-header>
      <h1 i18n>How does our trial work?</h1>
    </mat-card-header>

    <mat-card-content>
      <p i18n>
        First, we meet online to say hello! After a quick virtual tour of the system, we send you an account login. You can use the system for up 60 days, completely free.
      </p>
    </mat-card-content>
  </mat-card>

</section>
