import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RedsysService } from '../redsys.service';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.css']
})
export class SubscribeComponent implements OnInit {

  registrationForm: FormGroup;
  amount: number = 72;
  paymentSuccess: boolean = false;

  showRedsys: boolean = true;
  showError: boolean = false;
  errorMessage: string = "";

  randNumber: string = Math.floor((Math.random() * 1000000000) + 1).toFixed(0);

  constructor(
    private redsysService: RedsysService
  ) { }

  ngOnInit(): void {
    this.registrationForm = new FormGroup({
      propertyName: new FormControl("", Validators.required),
      contactName: new FormControl("", Validators.required),
      email: new FormControl("", Validators.email),
      phone: new FormControl("", Validators.required),
      propertyAddress: new FormControl("", Validators.required),
      vatNumber: new FormControl("")
    });
  }

  markFormAsTouched() {
    this.registrationForm.markAllAsTouched();
  }

  processPayment(redsysInfo: { idOper: string, merchantOrder: string }) {
    this.showRedsys = false;
    this.showError = false;

    this.redsysService.processOperation(redsysInfo.idOper, redsysInfo.merchantOrder, { amount: this.amount }, this.registrationForm.value).subscribe(res => {

      if (res.success)
        this.paymentSuccess = true;
      else {
        this.showError = true;
        this.errorMessage = `Error processing the payment: ${res.error || res.result.errorCode}`;
      } 

      this.showRedsys = true;
    }, error => {
      this.showError = true;
      this.errorMessage = "Service not available. Please, contact support@suiteclerk.com."
      this.showRedsys = true;
    });
  }
}
