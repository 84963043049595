<footer fxLayout="column" fxLayoutAlign="space-between center">
  <div class="footer-tabs" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-around flex-start" fxLayoutAlign.lt-md="space-around center">
    <div fxLayout="column" fxLayoutAlign="flex-start flex-start" fxLayoutAlign.lt-md="flex-start center">
      <h1 i18n class="title">Products</h1>
      <span i18n routerLink="/products/channel-pms">PMS & Channel Manager</span>
      <span i18n routerLink="/products/booking-button">Booking Button</span>
      <span i18n routerLink="/products/website-design">Website Design</span>
      <span i18n routerLink="/products/statistics-revenue">Statistics & Revenue Management</span>
      <span i18n routerLink="/products/working-schedule">Working Schedule Module</span>
      <span i18n routerLink="/products/intelligent-pricing">Intelligent Pricing</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="flex-start flex-start" fxLayoutAlign.lt-md="flex-start center">
      <h1 i18n class="title">Support</h1>
      <span i18n routerLink="/faq">FAQ</span>
      <span i18n (click)="openChat()">Help Center</span>
      <span i18n routerLink="/contact">Contact</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="flex-start flex-start" fxLayoutAlign.lt-md="flex-start center">
      <h1 i18n class="title">About</h1>
      <span i18n>Blog</span>
      <span i18n>Case Studies</span>
      <span i18n>Videos</span>
      <span i18n>Infographics</span>
      <span i18n>Slideshares</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="flex-start flex-start" fxLayoutAlign.lt-md="flex-start center">
      <h1 i18n class="title">Company</h1>
      <span i18n routerLink="/subscribe">Subscribe</span>
      <span i18n routerLink="/hiring">Careers</span>
      <span i18n>Events</span>
      <span i18n>Legal</span>
      <span i18n>News & Press</span>
    </div>
  </div>
  <div class="icons">
    <img src="/images/suiteclerk/pci-icon.png" width="50px"/>
  </div>
  <p i18n>
    SuiteClerk © 2018-2022  &nbsp;&nbsp;|&nbsp;&nbsp;  <span routerLink="/privacy-policy">Privacy Policy</span>  &nbsp;&nbsp;|&nbsp;&nbsp;  <span routerLink="/terms-conditions">Terms & Conditions</span>
  </p>
</footer>
