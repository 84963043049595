import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from 'rxjs';

import { Socket } from 'ngx-socket-io';

import { Message } from "./models/Message";
import { Chat } from "./models/Chat";

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  connectionOn: boolean;

  constructor(
    private socket: Socket,
    public http: HttpClient
  ) { }

  newChat(chatInfo: Chat): Observable<any> {
    return this.http.post<any>(`/api/chat/new-sc-chat`, chatInfo);
  }

  addMessage(chat_id: string, message): Observable<any> {

    return this.http.post<any>(`/api/chat/add-message-chat/${chat_id}`, message);
  }

  sendMessage(chat_id: string, message) {
    this.socket.emit("clientMessage", { chat_id: chat_id, message: message });
  }

  onNewMessage(): Observable<any> {
    return this.socket.fromEvent<any>("serverToClientMessage");
  }

  onTyping(): Observable<any> {
    return this.socket.fromEvent<any>("serverTyping");
  }

  notifyNewChat(chat_id: string) {

    this.socket.emit("newChat", chat_id);
  }

  startSocketConnection(chat_id: string) {

    this.socket.emit("startListening", chat_id);

    this.connectionOn = true;

    this.socket.on("disconnect", () => console.log("Socket disconnected"));
    this.socket.on("connect", () => console.log("Socket connected"));
    this.socket.on('reconnect_attempt', () => console.log("Trying reconnection..."));
  }

}
