<header class="product-header" fxLayout="row" fxLayoutAlign="space-around center" fxLayout.lt-sm="column">
  <h1 i18n>Website Design</h1>
  <img src="/images/suiteclerk/web-design.png"/>
</header>
<section class="product-section">

  <article class="features" fxLayout="row wrap" fxLayoutAlign="center flex-start">

    <div class="feature" fxLayout="row" fxLayoutAlign="space-between flex-start">
      <mat-icon>check_circle_outline</mat-icon>

      <div fxLayout="column" fxLayoutAlign="center flex-start">
        <h1 i18n>Fully Responsive</h1>
        <p i18n>
          Works perfectly on mobile, tablet and desktop. Secured bookings and instant confirmations.
        </p>
      </div>
    </div>

    <div class="feature" fxLayout="row" fxLayoutAlign="space-between flex-start">
      <mat-icon>check_circle_outline</mat-icon>

      <div fxLayout="column" fxLayoutAlign="center flex-start">
        <h1 i18n>Customisable</h1>
        <p i18n>
          Choose from a range of colours, headers, fonts and free canvas templates to make your site truly unique.
        </p>
      </div>
    </div>

    <div class="feature" fxLayout="row" fxLayoutAlign="space-between flex-start">
      <mat-icon>check_circle_outline</mat-icon>

      <div fxLayout="column" fxLayoutAlign="center flex-start">
        <h1 i18n>Great Value</h1>
        <p i18n>
          Free hosting, backup and recovery.  No design fees, no setup costs, no maintenance.
        </p>
      </div>
    </div>

    <div class="feature" fxLayout="row" fxLayoutAlign="space-between flex-start">
      <mat-icon>check_circle_outline</mat-icon>

      <div fxLayout="column" fxLayoutAlign="center flex-start">
        <h1 i18n>Full Ownership</h1>
        <p i18n>
          Your domain.  You own it and it stays with you no matter what.
        </p>
      </div>
    </div>

    <div class="feature" fxLayout="row" fxLayoutAlign="space-between flex-start">
      <mat-icon>check_circle_outline</mat-icon>

      <div fxLayout="column" fxLayoutAlign="center flex-start">
        <h1 i18n>Search Engine Optimised</h1>
        <p i18n>
          Our website and booking pages are optimised for SEO and high conversion rates, to help you get found and make more bookings.
        </p>
      </div>
    </div>

    <div class="feature" fxLayout="row" fxLayoutAlign="space-between flex-start">
      <mat-icon>check_circle_outline</mat-icon>

      <div fxLayout="column" fxLayoutAlign="center flex-start">
        <h1 i18n>Professional</h1>
        <p i18n>
          Get a website that looks professional, without the hassle of finding and managing web developers and uncertainty of costs.
        </p>
      </div>
    </div>

  </article>

</section>
