<button *ngIf="!chatOpened" mat-raised-button class="msg-button" (click)="openChat()">
  <mat-icon>question_answer</mat-icon>
</button>
<button *ngIf="chatOpened" mat-raised-button class="msg-button" (click)="closeChat()">
  <mat-icon>clear</mat-icon>
</button>

<mat-card *ngIf="chatOpened && chat" class="chat-card mat-elevation-z15" (mouseleave)="closeTimeoutChat()" (mouseenter)="keepOpen()">

  <mat-card-header>
    <div mat-card-avatar class="contact-img"></div>
    <mat-card-title>Claire Zip</mat-card-title>
    <mat-card-subtitle>Sales Executive</mat-card-subtitle>
  </mat-card-header>

  <mat-divider [inset]="true"></mat-divider>

  <mat-card-content class="messages-container" fxLayout="column" fxLayoutAlign="flex-start flex-start">
    <mat-card *ngFor="let message of chat.messages_list" class="message {{message.origin}}-side">
      {{message.text}}
    </mat-card>

    <mat-card *ngIf="typing" class="message server-side typing">
      <div id="wave">
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
      </div>
    </mat-card>
  </mat-card-content>

  <div class="message-input-container">

    <mat-form-field class="message-input" appearance="outline">
      <input matInput placeholder="Enter text..." [(ngModel)]="newMessage.text" (keyup.enter)="sendMessage()"/>
    </mat-form-field>

  </div>
</mat-card>
