<header class="pricing-header" fxLayout="column" fxLayoutAlign="center center">
  <h1 i18n>No setup fees. No hidden costs.</h1>
</header>
<section i18n fxLayout="column" fxLayoutAlign="flex-start center">
  <div class="section-header" fxLayout="row" fxLayoutAlign="space-around center">

    <div class="beds-input" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center">
      <span>How many rooms does your property have?</span>
      <mat-form-field appearance="outline">
        <mat-label>Rooms</mat-label>
        <input matInput placeholder="Rooms" type="number" [(ngModel)]="beds" (input)="bedsChange()" (change)="resetBeds()" />
      </mat-form-field>

    </div>

  </div>

  <div class="pricing-cards" fxLayout="column" fxLayoutAlign="flex-start center">
    <mat-card class="pricing-card mat-elevation-z8" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start center">
      <mat-card-header fxLayout="column" fxLayoutAlign="center center">
        <mat-card-title><img src="/images/suiteclerk/sc-logo-i.png" /> Essential<br />Package</mat-card-title>
        <h1 *ngIf="prices[0] != 0">{{prices[0]}} €</h1>
        <h1 *ngIf="prices[0] == 0">Contact Us</h1>
      </mat-card-header>
      <mat-card-content fxLayout="row" fxLayout.lt-md="row wrap" fxLayoutAlign="space-between center">

        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Channel Manager <br /> & PMS in one</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Booking Button</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Statistics, Revenue & Working Module</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Bill Processing</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Customizable Users</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Multi-property</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">PC Browser <br />& Android/iOS App</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Technical Support <br />Chat&Phone 24/7</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Automated Police <br /> Registration Form</span>
          <mat-icon class="cross-icon">clear</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Intelligent Pricing</span>
          <mat-icon class="cross-icon">clear</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Web Design</span>
          <mat-icon class="cross-icon">clear</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">First Channel Free  <br /> Extra Channels</span>
          <span class="cross-icon extra-channel">+8€/channel</span>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="pricing-card mat-elevation-z8" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start center">
      <mat-card-header fxLayout="column" fxLayoutAlign="center center">
        <mat-card-title><img src="/images/suiteclerk/sc-logo-i.png" /> EasyConnect<br />Package</mat-card-title>
        <h1 *ngIf="prices[1] != 0">{{prices[1]}} €</h1>
        <h1 *ngIf="prices[1] == 0">Contact Us</h1>
      </mat-card-header>
      <mat-card-content fxLayout="row" fxLayout.lt-md="row wrap" fxLayoutAlign="space-between center">
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Channel Manager <br /> & PMS in one</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Booking Button</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Statistics, Revenue & Working Module</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Bill Processing</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Customizable Users</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Multi-property</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">PC Browser <br />& Android/iOS App</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Technical Support <br />Chat&Phone 24/7</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Automated Police <br /> Registration Form</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Intelligent Pricing</span>
          <mat-icon class="cross-icon">clear</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Web Design</span>
          <mat-icon class="cross-icon">clear</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">First Channel Free  <br /> Extra Channels</span>
          <span class="cross-icon extra-channel">+8€/channel</span>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="pricing-card mat-elevation-z8" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start center">
      <mat-card-header fxLayout="column" fxLayoutAlign="center center">
        <mat-card-title><img src="/images/suiteclerk/sc-logo-i.png" /> Professional<br />Package</mat-card-title>
        <h1 *ngIf="prices[2] != 0">{{prices[2]}} €</h1>
        <h1 *ngIf="prices[2] == 0">Contact Us</h1>
      </mat-card-header>
      <mat-card-content fxLayout="row" fxLayout.lt-md="row wrap" fxLayoutAlign="space-between center">

        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Channel Manager <br /> & PMS in one</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Booking Button</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Statistics, Revenue & Working Module</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Bill Processing</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Customizable Users</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Multi-property</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">PC Browser <br />& Android/iOS App</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Technical Support <br />Chat&Phone 24/7</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Automated Police <br /> Registration Form</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Intelligent Pricing</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Web Design</span>
          <mat-icon class="cross-icon">clear</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">First Channel Free  <br /> Extra Channels</span>
          <span class="cross-icon extra-channel">+8€/channel</span>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="pricing-card mat-elevation-z8" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start center">
      <mat-card-header fxLayout="column" fxLayoutAlign="center center">
        <mat-card-title><img src="/images/suiteclerk/sc-logo-i.png" /> Enterprise<br />Package</mat-card-title>
        <h1 *ngIf="prices[3] != 0">{{prices[3]}} €</h1>
        <h1 *ngIf="prices[3] == 0">Contact Us</h1>
      </mat-card-header>
      <mat-card-content fxLayout="row" fxLayout.lt-md="row wrap" fxLayoutAlign="space-between center">

        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Channel Manager <br /> & PMS in one</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Booking Button</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Statistics, Revenue & Working Module</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Bill Processing</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Customizable Users</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Multi-property</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">PC Browser <br />& Android/iOS App</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Technical Support <br />Chat&Phone 24/7</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Automated Police <br /> Registration Form</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Intelligent Pricing</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">Web Design</span>
          <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="pricing-product" fxLayout="column" fxLayoutAlign="space-between center">
          <span class="product-name">First Channel Free  <br /> Extra Channels</span>
          <span class="cross-icon extra-channel">+8€/channel</span>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</section>
