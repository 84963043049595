import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hostels',
  templateUrl: './hostels.component.html',
  styleUrls: ['./hostels.component.css']
})
export class HostelsComponent implements OnInit {

  promoCode: string;
  beds: number = 10;
  prices: number[] = [48, 59, 67, 74];
  constructor() { }

  ngOnInit(): void {
  }

  resetBeds() {

    if (!this.beds)
      this.beds = 10;

  }

  bedsChange() {

    if (this.beds <= 50) {
      this.prices[0] = 48;
      this.prices[1] = 59;
      this.prices[2] = 67;
      this.prices[3] = 74;
    } else if (this.beds > 50 && this.beds <= 80) {
      this.prices[0] = 58;
      this.prices[1] = 69;
      this.prices[2] = 77;
      this.prices[3] = 84;
    } else if (this.beds > 80 && this.beds <= 100) {
      this.prices[0] = 68;
      this.prices[1] = 79;
      this.prices[2] = 87;
      this.prices[3] = 94;
    } else if (this.beds > 100 && this.beds <= 120) {
      this.prices[0] = 87;
      this.prices[1] = 99;
      this.prices[2] = 107;
      this.prices[3] = 114;
    } else if (this.beds > 120 && this.beds <= 150) {
      this.prices[0] = 107;
      this.prices[1] = 119;
      this.prices[2] = 127;
      this.prices[3] = 134;
    } else {
      this.prices[0] = 0;
      this.prices[1] = 0;
      this.prices[2] = 0;
      this.prices[3] = 0;
    }
  }

}
