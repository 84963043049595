import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router";

import { SocketService } from "./socket.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    public router: Router,
    public socketService: SocketService
  ) {}

  ngOnInit() {

    this.router.events.subscribe((evt) => {

      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      window.scrollTo(0, 0)
    });
  }
}
