import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  contactInfo: {
    name: string,
    email: string,
    phone: string,
    propertyName: string,
    nRooms: string
  } = { name: undefined, email: undefined, phone: undefined, propertyName: undefined, nRooms: undefined };
  sendingEmail: boolean = false;
  emailConfirmation: boolean = false;

  constructor(public http: HttpClient) { }

  ngOnInit() {
  }

  sendContactEmail() {
    console.log("sendEmail")
    this.sendingEmail = true;
    this.http.post<any>("api/send-contact-email", this.contactInfo).subscribe(res => {
      this.sendingEmail = false;
      this.emailConfirmation = true;
    });
  }

}
