import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from "./home/home.component";
import { PricingComponent } from "./pricing/pricing.component";
import { HostelsComponent } from "./hostels/hostels.component";
import { PmsComponent } from "./products/pms/pms.component";
import { BookingButtonComponent } from './products/booking-button/booking-button.component';
import { WebsiteDesignComponent } from './products/website-design/website-design.component';
import { StatisticsRevenueComponent } from './products/statistics-revenue/statistics-revenue.component';
import { WorkingScheduleComponent } from './products/working-schedule/working-schedule.component';
import { IntelligentPricingComponent } from './products/intelligent-pricing/intelligent-pricing.component';
import { ContactComponent } from "./contact/contact.component";
import { HiringComponent } from "./hiring/hiring.component";
import { FaqComponent } from "./faq/faq.component";
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { SubscribeComponent } from './subscribe/subscribe.component';

const routes: Routes = [
  { path: "home", component: HomeComponent },
  { path: "products/channel-pms", component: PmsComponent },
  { path: "products/booking-button", component: BookingButtonComponent },
  { path: "products/website-design", component: WebsiteDesignComponent },
  { path: "products/statistics-revenue", component: StatisticsRevenueComponent },
  { path: "products/working-schedule", component: WorkingScheduleComponent },
  { path: "products/intelligent-pricing", component: IntelligentPricingComponent },
  { path: "pricing", component: PricingComponent },
  { path: "hostels", component: HostelsComponent },
  { path: "contact", component: ContactComponent },
  { path: "hiring", component: HiringComponent },
  { path: "faq", component: FaqComponent },
  { path: "privacy-policy", component: PrivacyPolicyComponent },
  { path: "terms-conditions", component: TermsConditionsComponent },
  { path: "subscribe", component: SubscribeComponent },
  { path: '**', redirectTo: "home", pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
