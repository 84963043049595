<section #contact class="contact-form" fxLayout="column" fxLayoutAlign="center center">
<!--
  <video autoplay="" muted="" loop="" class="contact-video" oncanplay="this.play()" onloadedmetadata="this.muted = true" autoplay muted loop>
      <source src="/videos/world.mp4" type="video/mp4">
  </video>-->

  <!--<h1 class="section-title">Connecting the world</h1>-->
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center" fxLayoutAlign.lt-md="center center">

    <mat-card class="contact-card mat-elevation-z8" fxLayout="column" fxLayoutAlign="space-around center" fxLayout.lt-sm="column">

      <mat-card-header>
        <mat-card-title i18n>Contact Information</mat-card-title>
      </mat-card-header>

      <mat-form-field appearance="outline">
        <mat-label i18n>Name</mat-label>
        <mat-icon matPrefix>perm_identity</mat-icon>
        <input i18n-placeholder matInput autocomplete="name" placeholder="Name" [(ngModel)]="contactInfo.name" required />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label i18n>Email</mat-label>
        <mat-icon matPrefix>mail_outline</mat-icon>
        <input i18n-placeholder matInput autocomplete="email" placeholder="Email" [(ngModel)]="contactInfo.email" required />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label i18n>Phone Number</mat-label>
        <mat-icon matPrefix>phone</mat-icon>
        <input i18n-placeholder matInput autocomplete="tel" placeholder="Phone" [(ngModel)]="contactInfo.phone" required />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label i18n>Property Name</mat-label>
        <mat-icon matPrefix>house</mat-icon>
        <input i18n-placeholder matInput placeholder="Property Name" [(ngModel)]="contactInfo.propertyName" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label i18n>Number of Rooms</mat-label>
        <mat-icon matPrefix>hotel</mat-icon>
        <input i18n-placeholder matInput placeholder="Number of Rooms" [(ngModel)]="contactInfo.nRooms" />
      </mat-form-field>

      <button i18n *ngIf="!sendingEmail" mat-raised-button class="contact-btn" [disabled]="!(contactInfo.name && contactInfo.email && contactInfo.phone)" (click)="sendContactEmail()">Submit</button>
      <mat-spinner *ngIf="sendingEmail"></mat-spinner>
      <div *ngIf="emailConfirmation" class="alert-success confirmation">
        <span>We will contact you soon</span>
      </div>
    </mat-card>
  </div>

  <!--<img class="bottom-bg" src="/images/suiteclerk/gradient-bg.png"/>-->

</section>
