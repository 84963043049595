<div class="square-background"></div>
<section class="home-section">
  <header fxLayout="row" fxLayoutAlign="space-between center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center">
    <video autoplay muted loop playsinline class="header-video" oncanplay="this.play()" onloadedmetadata="this.muted = true">
        <source src="/videos/suiteclerk-video.mp4" type="video/mp4">
    </video>
    <div class="header-diagonal"></div>
    <!--<div class="header-overlay-1"></div>
    <div class="header-overlay-2"></div>-->

    <div class="header-logo" fxLayout="column" fxLayoutAlign="center flex-start">
      <!--<h1 i18n class="logo"><span i18n class="Suite">Suite</span>Clerk</h1>-->

      <p i18n>
        Get your <span class="header-words">fastest</span> <br /> cloud hotel platform
      </p>

      <a class="intranet-link" href="/intranet"><button i18n mat-raised-button class="trial-btn">30 Days Free Trial</button></a>
      <a class="mobile-contact-link" href="/intranet"><button i18n mat-raised-button class="trial-btn">30 Days Free Trial</button></a>
      <p i18n>
        No comittment. No payment.
      </p>

      <!--<p i18n class="header-info">
        Get the best PMS and Channel Manager <br />
        to cover all your needs.
      </p>-->
    </div>
<!--
    <div class="header-awards" fxLayout="column" fxLayoutAlign="space-around center" fxLayout.lt-md="row" fxLayout.lt-sm="column">
      <div class="header-feature"  fxLayout="row" fxLayoutAlign="flex-start center" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="space-between center">
        <img src="/images/suiteclerk/faster.svg" />
        <span>i18n
          Instant load
        </span>
      </div>

      <div class="header-feature"  fxLayout="row" fxLayoutAlign="flex-start center" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="space-between center">
        <img src="/images/suiteclerk/async.svg" />
        <span>i18n
          Asynchronous technology
        </span>
      </div>
    </div>-->
    <div class="technical-supp">
      <p i18n>Customer Sales Service</p>
      <p i18n class="customer-phone">
        <img src="/images/icons/headset_msg.svg" /> +34 648 81 33 14
      </p>
    </div>
  </header>

  <article fxLayout="column" fxLayoutAlign="center center">

    <!--<h1 i18n class="article-title">Get the best Channel Manager & PMS to cover all your needs.</h1>-->

    <div class="products" fxLayout="row wrap" fxLayoutAlign="center center">


      <mat-card class="product-card mat-elevation-z8" routerLink="/products/channel-pms">
        <mat-card-header>
          <mat-card-title i18n>Channel Manager & PMS in one</mat-card-title>
        </mat-card-header>
        <img class="product-icon" src="/images/suiteclerk/cm-pms.svg" />
        <mat-card-content fxLayout="column" fxLayoutAlign="space-between center">
          <p i18n>
            We automate all you need into a single page.
          </p>
          <p i18n class="learn-more">
            Learn more >
          </p>
        </mat-card-content>
      </mat-card>


      <mat-card class="product-card mat-elevation-z8" routerLink="/products/booking-button">
        <mat-card-header>
          <mat-card-title i18n>Booking Button</mat-card-title>
        </mat-card-header>
        <img class="product-icon" src="/images/suiteclerk/booking-btn.svg" />
        <mat-card-content fxLayout="column" fxLayoutAlign="space-between center">
          <p i18n>
            Avoid fees to third parties by getting direct bookings.
          </p>
          <p i18n class="learn-more">
            Learn more >
          </p>
        </mat-card-content>
      </mat-card>

      <mat-card class="product-card mat-elevation-z8" routerLink="/products/website-design">
        <mat-card-header>
          <mat-card-title i18n>Website Design</mat-card-title>
        </mat-card-header>
        <img class="product-icon" src="/images/suiteclerk/website-design.svg" />
        <mat-card-content fxLayout="column" fxLayoutAlign="space-between center">
          <p i18n>
            Attract more customers by making the difference with our elegant design.
          </p>
          <p i18n class="learn-more">
            Learn more >
          </p>
        </mat-card-content>
      </mat-card>

      <mat-card class="product-card mat-elevation-z8" routerLink="/products/statistics-revenue">
        <mat-card-header>
          <mat-card-title i18n>Statistics & Revenue Management</mat-card-title>
        </mat-card-header>
        <img class="product-icon" src="/images/suiteclerk/statistics.svg" />
        <mat-card-content fxLayout="column" fxLayoutAlign="space-between center">
          <p i18n>
            Monitor and improve every aspect of your business.
          </p>
          <p i18n class="learn-more">
            Learn more >
          </p>
        </mat-card-content>
      </mat-card>

      <mat-card class="product-card mat-elevation-z8" routerLink="/products/working-schedule">
        <mat-card-header>
          <mat-card-title i18n>Working Schedule Module</mat-card-title>
        </mat-card-header>
        <img class="product-icon" src="/images/suiteclerk/working-schedule.svg" />
        <mat-card-content fxLayout="column" fxLayoutAlign="space-between center">
          <p i18n>
            Organise every shift and every duty to run your business smoothly.
          </p>
          <p i18n class="learn-more">
            Learn more >
          </p>
        </mat-card-content>
      </mat-card>

      <mat-card class="product-card mat-elevation-z8" routerLink="/products/intelligent-pricing">
        <mat-card-header>
          <mat-card-title i18n>Intelligent Pricing</mat-card-title>
        </mat-card-header>
        <img class="product-icon" src="/images/suiteclerk/intel-pricing.svg" />
        <mat-card-content fxLayout="column" fxLayoutAlign="space-between center">
          <p i18n>
            Our AI makes you to stay competitive even when you are not logging in.
          </p>
          <p i18n class="learn-more">
            Learn more >
          </p>
        </mat-card-content>
      </mat-card>

    </div>
  </article>

  <article class="channels" fxLayout="column" fxLayoutAlign="center center">

    <mat-card class="channels-card mat-elevation-z8" fxLayout="row wrap" fxLayoutAlign="space-around center" fxLayout.lt-sm="column">
      <img class="channel-logo booking" src="/images/suiteclerk/booking.png" />
      <img class="channel-logo expedia" src="/images/suiteclerk/expedia.png" />
      <img class="channel-logo airbnb" src="/images/suiteclerk/airbnb.png" />
      <img class="channel-logo whathostel" src="/images/suiteclerk/whathostel.png" />
      <img class="channel-logo hostelworld" src="/images/suiteclerk/hostelworld.png" />
      <img class="channel-logo" src="/images/suiteclerk/orbitz.png" />
      <img class="channel-logo" src="/images/suiteclerk/homeaway.png" />
      <img class="channel-logo" src="/images/suiteclerk/hotelscom.png" />
      <img class="channel-logo" src="/images/suiteclerk/wimdu.png" />
      <img class="channel-logo" src="/images/suiteclerk/hrs.png" />
      <img class="channel-logo booking" src="/images/suiteclerk/lastminute.png" />
      <img class="channel-logo booking" src="/images/suiteclerk/travelocity.png" />
      <img class="channel-logo booking" src="/images/suiteclerk/hostelbookers.png" />
      <img class="channel-logo booking" src="/images/suiteclerk/hostelclub.png" />
      <img class="channel-logo booking" src="/images/suiteclerk/ebookers.png" />
      <img class="channel-logo booking" src="/images/suiteclerk/hotelbeds.png" />
    </mat-card>


  </article>

  <article class="features" fxLayout="column" fxLayoutAlign="center center">
<!--
    <video autoplay="" muted="" loop="" class="contact-video" oncanplay="this.play()" onloadedmetadata="this.muted = true" autoplay muted loop>
        <source src="/videos/world.mp4" type="video/mp4">
    </video>-->

    <h1 i18n class="article-title">Connecting the world</h1>
    <div class="features" fxLayout="row" fxLayoutAlign="center flex-start">

      <div class="feature" fxLayout="column" fxLayoutAlign="flex-start center">
        <div class="circle">
          <img src="/icons/switch.svg" />
        </div>

        <p i18n class="feature-text">
          Get up to 6 months for free. We pay you the transition cost
        </p>
      </div>

      <div class="feature" fxLayout="column" fxLayoutAlign="flex-start center">
        <div class="circle">
          <img src="/icons/support.svg" />
        </div>

        <p i18n class="feature-text">
        24/7 Support. We visit you wherever you are
        </p>
      </div>

      <div class="feature" fxLayout="column" fxLayoutAlign="flex-start center">
        <div class="circle">
          <img src="/icons/speed.svg" />
        </div>

        <p i18n class="feature-text">
          Instant load. Get the fastest asynchronous technology
        </p>
      </div>
    </div>
    <div class="features" fxLayout="row" fxLayoutAlign="center flex-start">

      <div class="feature" fxLayout="column" fxLayoutAlign="flex-start center">
        <div class="circle">
          <img src="/icons/channel.svg" />
        </div>

        <p i18n class="feature-text">
          We give you access to all the channels with no limit
        </p>
      </div>

      <div class="feature" fxLayout="column" fxLayoutAlign="flex-start center">
        <div class="circle">
          <img src="/icons/data.svg" />
        </div>

        <p i18n class="feature-text">
          No downloads. No installs
        </p>
      </div>

    </div>

    <!--<img class="bottom-bg" src="/images/suiteclerk/gradient-bg.png"/>-->

  </article>

  <article #contact class="contact-form" fxLayout="column" fxLayoutAlign="center center">
<!--
    <video autoplay="" muted="" loop="" class="contact-video" oncanplay="this.play()" onloadedmetadata="this.muted = true" autoplay muted loop>
        <source src="/videos/world.mp4" type="video/mp4">
    </video>-->

    <!--<h1 i18n class="article-title">Connecting the world</h1>-->
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center">

      <mat-card class="contact-card mat-elevation-z8" fxLayout="column" fxLayoutAlign="space-around center" fxLayout.lt-sm="column">

        <mat-card-header>
          <mat-card-title i18n>Contact Information</mat-card-title>
        </mat-card-header>

        <mat-form-field appearance="outline">
          <mat-label i18n>Name</mat-label>
          <mat-icon matPrefix>perm_identity</mat-icon>
          <input i18n-placeholder matInput autocomplete="name" placeholder="Name" [(ngModel)]="contactInfo.name" required />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label i18n>Email</mat-label>
          <mat-icon matPrefix>mail_outline</mat-icon>
          <input i18n-placeholder matInput autocomplete="email" placeholder="Email" [(ngModel)]="contactInfo.email" required />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label i18n>Phone Number</mat-label>
          <mat-icon matPrefix>phone</mat-icon>
          <input i18n-placeholder matInput autocomplete="tel" placeholder="Phone" [(ngModel)]="contactInfo.phone" required />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label i18n>Property Name</mat-label>
          <mat-icon matPrefix>house</mat-icon>
          <input i18n-placeholder matInput placeholder="Property Name" [(ngModel)]="contactInfo.propertyName" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label i18n>Number of Rooms</mat-label>
          <mat-icon matPrefix>hotel</mat-icon>
          <input i18n-placeholder matInput placeholder="Number of Rooms" [(ngModel)]="contactInfo.nRooms" />
        </mat-form-field>

        <button i18n *ngIf="!sendingEmail" mat-raised-button class="contact-btn" [disabled]="!(contactInfo.name && contactInfo.email && contactInfo.phone)" (click)="sendContactEmail()">Submit</button>
        <mat-spinner *ngIf="sendingEmail"></mat-spinner>
        <div *ngIf="emailConfirmation" class="alert-success confirmation">
          <span>We will contact you soon</span>
        </div>
      </mat-card>
    </div>

    <!--<img class="bottom-bg" src="/images/suiteclerk/gradient-bg.png"/>-->

  </article>

</section>
