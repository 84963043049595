<header class="product-header" fxLayout="row" fxLayoutAlign="space-around center" fxLayout.lt-sm="column">
  <h1 i18n>Statistics & Revenue <br /> Management</h1>
  <img src="/images/suiteclerk/statistics.png"/>
</header>
<section class="product-section">

  <article class="features" fxLayout="row wrap" fxLayoutAlign="center flex-start">

    <div class="feature" fxLayout="row" fxLayoutAlign="space-between flex-start">
      <mat-icon>check_circle_outline</mat-icon>

      <div fxLayout="column" fxLayoutAlign="center flex-start">
        <h1 i18n>Optimise your revenue</h1>
        <p i18n>
          Make smarter and more confident pricing and distribution decisions to optimise your revenue and maximise occupancy.
        </p>
      </div>
    </div>

    <div class="feature" fxLayout="row" fxLayoutAlign="space-between flex-start">
      <mat-icon>check_circle_outline</mat-icon>

      <div fxLayout="column" fxLayoutAlign="center flex-start">
        <h1 i18n>Save time</h1>
        <p i18n>
          Free yourself from spreadsheets and save time with data you can trust, easily available from a single place.
        </p>
      </div>
    </div>

    <div class="feature" fxLayout="row" fxLayoutAlign="space-between flex-start">
      <mat-icon>check_circle_outline</mat-icon>

      <div fxLayout="column" fxLayoutAlign="center flex-start">
        <h1 i18n>Beat your competition</h1>
        <p i18n>
          Have the foresight to beat your competition today and tomorrow.
        </p>
      </div>
    </div>

    <div class="feature" fxLayout="row" fxLayoutAlign="space-between flex-start">
      <mat-icon>check_circle_outline</mat-icon>

      <div fxLayout="column" fxLayoutAlign="center flex-start">
        <h1 i18n>Easy To Read Graphics</h1>
        <p i18n>
          Glance through a selection of meaningful graphics to review channel mix, year on year performance and demand, average rates and more.
        </p>
      </div>
    </div>

    <div class="feature" fxLayout="row" fxLayoutAlign="space-between flex-start">
      <mat-icon>check_circle_outline</mat-icon>

      <div fxLayout="column" fxLayoutAlign="center flex-start">
        <h1 i18n>Key Performance Indicators</h1>
        <p i18n>
          Monitor where guests come from, how full you are, your average price and average length of stay – over any period of your choice.
        </p>
      </div>
    </div>

    <div class="feature" fxLayout="row" fxLayoutAlign="space-between flex-start">
      <mat-icon>check_circle_outline</mat-icon>

      <div fxLayout="column" fxLayoutAlign="center flex-start">
        <h1 i18n>Quick Views</h1>
        <p i18n>
          Sort bookings by arrival, departure, or book date.  See who’s in-house, due to arrive or check out, and check your in-tray for recently arrived bookings.
      </div>
    </div>

  </article>

</section>
