import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RedsysService {

  constructor(
    private http: HttpClient
  ) { }

  processOperation(idOper: string, merchantOrder: string, transaction: { amount: number }, info?: any) {
    return this.http.post<any>(`api/payments/process-redsys-payment`, { idOper, merchantOrder, transaction, info });
  }
}
