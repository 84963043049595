<nav class="top-nav" [className]="'animated ' + (navFixed ? 'nav-fixed slideInDown' : 'nav-transparent')" (scroll)="onScroll($event)">

  <mat-card class="nav-card">

    <mat-toolbar class="nav-toolbar" fxLayout="row" fxLayoutAlign="space-between center">

      <button i18n class="logo" mat-button routerLink="/"><img src="/images/suiteclerk/sc-logo-i.png" width="100%" /></button>

      <div fxShow fxHide.lt-md class="nav-tabs" fxLayout="row" fxLayoutAlign="space-between center">
        <button i18n mat-button [matMenuTriggerFor]="menuProducts">Products</button>
        <button i18n mat-button routerLink="/pricing">Pricing</button>
        <button i18n mat-button routerLink="/hostels">Hostel Offer</button>
        <button i18n mat-button routerLink="/contact">Contact</button>
        <span class="phone-contact" fxLayout="row" fxLayoutAlign="space-between center"><mat-icon>phone</mat-icon> +34 648 81 33 14 </span>

        <button i18n mat-button class="hiring-btn" routerLink="/hiring"><mat-icon>person</mat-icon> We are hiring</button>
        <button i18n mat-stroked-button class="trial-btn"><a href="/intranet">30 Days Free Trial</a></button>
        <button i18n mat-button class="language-btn" [matMenuTriggerFor]="menuLanguages"><mat-icon>language</mat-icon></button>
      </div>

      <!-- Screen < 1200px -->
      <div fxHide fxShow.lt-md fxHide.lt-sm fxLayout="row" fxLayoutAlign="space-between center" class="nav-tabs md">
        <button i18n mat-button class="tabs-menu" [matMenuTriggerFor]="menuTabs"><mat-icon>menu</mat-icon></button>
        <button i18n mat-button class="hiring-btn"><mat-icon>person</mat-icon> We are hiring</button>
        <button i18n mat-stroked-button class="trial-btn"><a href="/intranet">30 Days Free Trial</a></button>
        <button i18n mat-button class="language-btn" [matMenuTriggerFor]="menuLanguages"><mat-icon>language</mat-icon></button>
      </div>
      <!---->

      <!-- Screen < 660px -->
      <div fxHide fxShow.lt-sm fxLayout="row" fxLayoutAlign="space-between center" class="nav-tabs sm">
        <button i18n mat-button class="tabs-menu" [matMenuTriggerFor]="menuTabs"><mat-icon>menu</mat-icon></button>
        <button i18n mat-stroked-button class="trial-btn"><a href="/intranet">30 Days Free Trial</a></button>
        <button i18n mat-button class="language-btn" [matMenuTriggerFor]="menuLanguages"><mat-icon>language</mat-icon></button>
      </div>
      <!---->

    </mat-toolbar>

  </mat-card>

</nav>

<mat-menu #menuTabs="matMenu">
  <button i18n mat-menu-item [matMenuTriggerFor]="menuProducts">Products</button>
  <button i18n mat-menu-item routerLink="/pricing">Pricing</button>
  <button i18n mat-menu-item routerLink="/hostels">Hostel Offer</button>
  <button i18n mat-menu-item routerLink="/contact">Contact</button>
</mat-menu>

<mat-menu #menuProducts="matMenu">
  <button mat-menu-item routerLink="/products/channel-pms">
    <mat-icon>device_hub</mat-icon>
    <span i18n>Channel Manager & PMS in one</span>
  </button>
  <button mat-menu-item routerLink="/products/booking-button">
    <mat-icon>radio_button_checked</mat-icon>
    <span i18n>Booking Button</span>
  </button>
  <button mat-menu-item routerLink="/products/website-design">
    <mat-icon>important_devices</mat-icon>
    <span i18n>Website Design</span>
  </button>
  <button mat-menu-item routerLink="/products/statistics-revenue">
    <mat-icon>trending_up</mat-icon>
    <span i18n>Statistics & Revenue Management</span>
  </button>
  <button mat-menu-item routerLink="/products/working-schedule">
    <mat-icon>settings_ethernet</mat-icon>
    <span i18n>Working Schedule Module</span>
  </button>
  <button mat-menu-item routerLink="/products/intelligent-pricing">
    <mat-icon>trending_up</mat-icon>
    <span i18n>Intelligent Pricing</span>
  </button>
</mat-menu>

<mat-menu class="menu lang-menu" #menuLanguages="matMenu">
  <div mat-menu-item *ngFor="let language of languageList" fxLayout="row" fxLayoutAlign="flex-start center">
    <span class="flag-icon flag-icon-{{language.icon}} flag-icon-squared"></span>
    <a class="lang-link" [href]="language.path + '/' + currentComponent" >
      {{language.label}}
    </a>
  </div>
</mat-menu>
