export class Chat {

  _id?: string;
  messages_list: {
    text: string,
    timestamp: Date
    origin: string;
    destination: string;
  }[];
  subject: string;
  avatar: string;
  type: number; // 0: hostel<->admin ; 1: hostel<->customer
  _participants: any[];

  constructor(hostel_id: any, avatar: string, type: number, subject?: string) {
    this.messages_list = [];
    hostel_id ? this._participants = [ hostel_id ] : this._participants = [];
    this.avatar = avatar;
    this.type = type;
    this.subject = subject;
    if (this.type == 2) {
      this.messages_list.unshift({
        text: "Hi! How can I help you? :)",
        timestamp: new Date(),
        origin: "server",
        destination: "client"
      });
    }
  }

}
