import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-website-design',
  templateUrl: './website-design.component.html',
  styleUrls: ['./website-design.component.css']
})
export class WebsiteDesignComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
