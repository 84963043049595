<header class="product-header" fxLayout="row" fxLayoutAlign="space-around center" fxLayout.lt-sm="column">
  <h1 i18n>Working Schedule <br /> Module</h1>
  <img src="/images/suiteclerk/working-schedule.png"/>
</header>
<section class="product-section">

  <article class="features" fxLayout="row wrap" fxLayoutAlign="center flex-start">

    <div class="feature" fxLayout="row" fxLayoutAlign="space-between flex-start">
      <mat-icon>check_circle_outline</mat-icon>

      <div fxLayout="column" fxLayoutAlign="center flex-start">
        <h1 i18n>Staff Management Tools</h1>
        <p i18n>
          Review time logs & transactions from every shift.
        </p>
      </div>
    </div>

    <div class="feature" fxLayout="row" fxLayoutAlign="space-between flex-start">
      <mat-icon>check_circle_outline</mat-icon>

      <div fxLayout="column" fxLayoutAlign="center flex-start">
        <h1 i18n>Scheduler</h1>
        <p i18n>
          Plan staff working timetables and optimise jobs.
        </p>
      </div>
    </div>

    <div class="feature" fxLayout="row" fxLayoutAlign="space-between flex-start">
      <mat-icon>check_circle_outline</mat-icon>

      <div fxLayout="column" fxLayoutAlign="center flex-start">
        <h1 i18n>Tasks assignament</h1>
        <p i18n>
          Define dutties and allocate them.
        </p>
      </div>
    </div>



  </article>

</section>
