import {BREAKPOINT, BreakPoint } from '@angular/flex-layout';

const CUSTOM_BREAKPOINTS: BreakPoint[] = [{
  alias: 'lt-md',
  mediaQuery: '(max-width: 1200px)',
}];


export const CustomBreakPointsProvider = {
  provide: BREAKPOINT,
  useValue: CUSTOM_BREAKPOINTS,
  multi: true
};
