import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {

  beds: number = 10;
  prices: number[] = [67, 86, 98, 109];
  constructor() { }

  ngOnInit(): void {
  }

  resetBeds() {

    if (!this.beds)
      this.beds = 10;

  }

  bedsChange() {

    if (this.beds <= 50) {
      this.prices[0] = 67;
      this.prices[1] = 86;
      this.prices[2] = 98;
      this.prices[3] = 109;
    } else if (this.beds > 50 && this.beds <= 80) {
      this.prices[0] = 87;
      this.prices[1] = 106;
      this.prices[2] = 118;
      this.prices[3] = 129;
    } else if (this.beds > 80 && this.beds <= 100) {
      this.prices[0] = 107;
      this.prices[1] = 126;
      this.prices[2] = 138;
      this.prices[3] = 149;
    } else if (this.beds > 100 && this.beds <= 120) {
      this.prices[0] = 127;
      this.prices[1] = 146;
      this.prices[2] = 158;
      this.prices[3] = 169;
    } else if (this.beds > 120 && this.beds <= 150) {
      this.prices[0] = 147;
      this.prices[1] = 166;
      this.prices[2] = 178;
      this.prices[3] = 189;
    } else if (this.beds > 150 && this.beds <= 200) {
      this.prices[0] = 167;
      this.prices[1] = 186;
      this.prices[2] = 198;
      this.prices[3] = 209;
    }  else {
      this.prices[0] = 0;
      this.prices[1] = 0;
      this.prices[2] = 0;
      this.prices[3] = 0;
    }

  }

}
