<header class="pricing-header" fxLayout="column" fxLayoutAlign="center center">
  <h1 i18n>Subscribe</h1>
</header>
<section>
  <div class="subscription-form">
    <h1>Registration Form</h1>
    <mat-card class="subscription-form">
      <mat-card-content>
        <form *ngIf="!paymentSuccess" [formGroup]="registrationForm">
          <mat-form-field appearance="outline">
            <mat-label>Property</mat-label>
            <input matInput type="text" formControlName="propertyName" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Contact Person</mat-label>
            <input matInput type="text" formControlName="contactName" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput type="email" formControlName="email" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Phone</mat-label>
            <input matInput type="phone" formControlName="phone" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Property Address</mat-label>
            <input matInput type="text" formControlName="propertyAddress" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>VAT Number</mat-label>
            <input matInput type="text" formControlName="vatNumber" />
          </mat-form-field>
          <b>Total amount (VAT Included): {{ amount | currency: 'EUR' }}</b>
          <app-redsys *ngIf="showRedsys"
            [merchantOrder]="randNumber" 
            [amount]="amount" 
            [disabled]="registrationForm.invalid"
            (submittedSuccess)="processPayment($event)"
            (submittedDisabled)="markFormAsTouched()">
          </app-redsys>
          <mat-spinner *ngIf="!showRedsys"></mat-spinner>
          <div *ngIf="showError" class="alert-error">{{ errorMessage }}</div>
        </form>
        <div *ngIf="paymentSuccess" class="alert-success">
          <p>
            <b>Property identifier: {{ randNumber }}</b>
          </p>
          <p>
            Contact +34 648 81 33 14 or support@suiteclerk.com with your property identifier to start the installation process. We will contact you in the following days otherwise.
          </p>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="subscription-details">
    <h1>Subscription Details</h1>
    <p>
      <b>SuiteClerk Software Licence</b> has a price of <b>{{ amount | currency: 'EUR' }} per month</b>. Registration grants access to the following products and features for <b>one month</b> after the configuration process is completed:
    </p>
    <ul>
      <li>Channel Manager & PMS all-in-one</li>
      <li>Booking Button</li>
      <li>Website Design</li>
      <li>Statistics & Revenue Management</li>
      <li>Working Schedule Module</li>
      <li>Intelligent Pricing</li>
    </ul>
    <p>
      After the purchase of the software licence, you can contact SuiteClerk support to start the configuration process or we will contact you in the following days.
    </p>
    <p>
      Free of charge cancellation is allowed in the first 7 days after the purchase, you can contact support to ask for a refund which will be executed in 7-14 working days using the same payment method.
      You won't be charge automaticallly, you will have to buy a new subscription when it expires.
    </p>
    <p>
      SuiteClerk Software Licence is sold and managed by DreamPulse SL, CIF B90386681, C/ Fernando De Herrera Numero 8 41704 - (Dos Hermanas) - Sevilla.
    </p>
    <p class="secured-payment">
      Payment is secured using SSL protocols and the 3DSecure system, redirecting the data to secured servers without being able to access or store them.
    </p>
  </div>
</section>