import { Component, OnInit } from '@angular/core';
import { SocketService } from "../socket.service";

import { Message } from "../models/Message";
import { Chat } from "../models/Chat";

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {

  chatOpened: boolean;

  chat: Chat;

  newMessage: Message = { text: "", origin: "client", destination: "server", timestamp: new Date() };

  typing: boolean;
  timer: any;
  closeTimer: any;

  constructor(public socketService: SocketService) { }

  ngOnInit(): void {
  }

  openChat() {
    if (!this.chat) {

      let chat = new Chat(null, "/images/sc-logo.png", 2);

      this.socketService.newChat(chat).subscribe(res => {
        if (res.success) {
          this.chat = chat;
          this.chat._id = res.chat_id;
          if (!this.socketService.connectionOn) {
            this.socketService.notifyNewChat(this.chat._id);
            this.socketService.startSocketConnection(this.chat._id);
            let audio = new Audio();
            audio.src = "../assets/audio/swiftly.mp3";
            audio.load();
            audio.play();
          }
        }

        setTimeout(() =>
        {
          var container = document.getElementsByClassName("messages-container")[0];
          container.scrollTop = container.scrollHeight;
        }, 100);
      });

      this.socketService.onTyping().subscribe(() => {
        this.typing = true;

        if (this.timer)
          clearTimeout(this.timer);

        this.timer = setTimeout(() => {
          this.typing = false;
        }, 1000);
      });

      this.socketService.onNewMessage().subscribe(message => {
        this.typing = false;
        this.chat.messages_list.push(message);
        let audio = new Audio();
        audio.src = "../assets/audio/swiftly.mp3";
        audio.load();
        audio.play();
        setTimeout(() =>
        {
          var container = document.getElementsByClassName("messages-container")[0];
          container.scrollTop = container.scrollHeight;
        }, 100);
      });

    } else {
      setTimeout(() =>
      {
        var containers = document.getElementsByClassName("messages-container");
        for (let i = 0 ; i < containers.length ; i++) {
          containers[i].scrollTop = containers[i].scrollHeight;
        }
      }, 100);
    }

    this.chatOpened = true;

  }
  closeChat() {
    this.chatOpened = false;
  }

  closeTimeoutChat() {
    this.closeTimer = setTimeout(() => this.closeChat(), 1000);
  }

  keepOpen() {
    if (this.closeTimer)
      clearTimeout(this.closeTimer);
  }

  sendMessage() {

    if (this.newMessage.text.length > 0) {
      let auxMessage = {
        text: this.newMessage.text,
        timestamp: new Date(),
        origin: "client",
        destination: "server"
      };

      this.socketService.addMessage(this.chat._id, auxMessage).subscribe(res => {
        if (res.success) {
          this.chat.messages_list.push(auxMessage);
          this.socketService.sendMessage(this.chat._id, auxMessage);
          this.newMessage.text = "";
          setTimeout(() =>
          {
            var containers = document.getElementsByClassName("messages-container");
            for (let i = 0 ; i < containers.length ; i++) {
              containers[i].scrollTop = containers[i].scrollHeight;
            }
          }, 100);
        }
      })
    }
  }

}
