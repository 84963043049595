import { Component, OnInit, HostListener } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router";

@Component({
  selector: 'app-top-navigation-bar',
  templateUrl: './top-navigation-bar.component.html',
  styleUrls: ['./top-navigation-bar.component.css']
})
export class TopNavigationBarComponent implements OnInit {

  languageList: any = [
    {
      label: "English",
      icon: "gb",
      path: "/en"
    },
    {
      label: "Español",
      icon: "es",
      path: "/es"
    }/*,
    {
      label: "François",
      icon: "fr"
    },
    {
      label: "Deutsch",
      icon: "de"
    },
    {
      label: "Italiano",
      icon: "it"
    },
    {
      label: "Portuguès",
      icon: "pt"
    },*/
  ]

  urlClass: string;
  navFixed: boolean = false;
  currentComponent: string;

  constructor(public router: Router) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {

      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      this.currentComponent = evt.url.split("/")[1];
    });
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    let scrollOffset = document.documentElement.scrollTop;

    if (!this.navFixed && scrollOffset > 100)
      this.navFixed = true;
    else if (scrollOffset == 0)
      this.navFixed = false;
  }

  


}
