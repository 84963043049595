<header class="hiring-header" fxLayout="column" fxLayoutAlign="center center">
  <h1 i18n>Build your career at SuiteClerk.</h1>
</header>
<section>

  <mat-card class="job-card mat-elevation-z8" routerLink="/contact">
    <mat-card-header>
      <h1 i18n>Sales Executive - Turkish Speaker</h1>
    </mat-card-header>

    <mat-card-content>
      <h1 i18n>Job Description</h1>
      <span i18n>- Secure new business and achieve your sales targets by conducting inbound and outbound phone calls to build your sales pipeline</span>
      <span i18n>- Introduce new Hotels and Accommodation providers to SuiteClerk and educate them on our products and services</span>
      <span i18n>- Identify potential upgrade opportunities with existing customers and generate new sales</span>
      <span i18n>- Become a product expert of SuiteClerks's software solutions and your target market</span>

      <h1 i18n>Aptitudes</h1>
      <span i18n>- 1+ years sales experience</span>
      <span i18n>- Fluent in Turkish and English (written and verbal)</span>
      <span i18n>- Experience with cold calling to gain new business</span>
      <span i18n>- Experience working with sales revenue targets and KPI’s</span>
      <span i18n>- Can-do attitude, resilience to overcome objections and motivation for a high volume fast paced sales environment</span>
      <span i18n>- Nice to have: industry experience within one of the following areas: SaaS, OTA/Wholesaler/Meta, Hotel Revenue Management, Channel Management or Hospitality</span>
    </mat-card-content>

  </mat-card>

  <mat-card class="job-card mat-elevation-z8" routerLink="/contact">
    <mat-card-header>
      <h1 i18n>Sales Executive - Norwegian Speaker</h1>
    </mat-card-header>

    <mat-card-content>
      <h1 i18n>Job Description</h1>
      <span i18n>- Secure new business and achieve your sales targets by conducting inbound and outbound phone calls to build your sales pipeline</span>
      <span i18n>- Introduce new Hotels and Accommodation providers to SuiteClerk and educate them on our products and services</span>
      <span i18n>- Identify potential upgrade opportunities with existing customers and generate new sales</span>
      <span i18n>- Become a product expert of SuiteClerks's software solutions and your target market</span>

      <h1 i18n>Aptitudes</h1>
      <span i18n>- 1+ years sales experience</span>
      <span i18n>- Fluent in Norwegian and English (written and verbal)</span>
      <span i18n>- Experience with cold calling to gain new business</span>
      <span i18n>- Experience working with sales revenue targets and KPI’s</span>
      <span i18n>- Can-do attitude, resilience to overcome objections and motivation for a high volume fast paced sales environment</span>
      <span i18n>- Nice to have: industry experience within one of the following areas: SaaS, OTA/Wholesaler/Meta, Hotel Revenue Management, Channel Management or Hospitality</span>
    </mat-card-content>

  </mat-card>

  <mat-card class="job-card mat-elevation-z8" routerLink="/contact">
    <mat-card-header>
      <h1 i18n>Sales Executive - German Speaker</h1>
    </mat-card-header>

    <mat-card-content>
      <h1 i18n>Job Description</h1>
      <span i18n>- Secure new business and achieve your sales targets by conducting inbound and outbound phone calls to build your sales pipeline</span>
      <span i18n>- Introduce new Hotels and Accommodation providers to SuiteClerk and educate them on our products and services</span>
      <span i18n>- Identify potential upgrade opportunities with existing customers and generate new sales</span>
      <span i18n>- Become a product expert of SuiteClerks's software solutions and your target market</span>

      <h1 i18n>Aptitudes</h1>
      <span i18n>- 1+ years sales experience</span>
      <span i18n>- Fluent in German and English (written and verbal)</span>
      <span i18n>- Experience with cold calling to gain new business</span>
      <span i18n>- Experience working with sales revenue targets and KPI’s</span>
      <span i18n>- Can-do attitude, resilience to overcome objections and motivation for a high volume fast paced sales environment</span>
      <span i18n>- Nice to have: industry experience within one of the following areas: SaaS, OTA/Wholesaler/Meta, Hotel Revenue Management, Channel Management or Hospitality</span>
    </mat-card-content>

  </mat-card>

  <mat-card class="job-card mat-elevation-z8" routerLink="/contact">
    <mat-card-header>
      <h1 i18n>Sales Executive - Chinese Speaker</h1>
    </mat-card-header>

    <mat-card-content>
      <h1 i18n>Job Description</h1>
      <span i18n>- Secure new business and achieve your sales targets by conducting inbound and outbound phone calls to build your sales pipeline</span>
      <span i18n>- Introduce new Hotels and Accommodation providers to SuiteClerk and educate them on our products and services</span>
      <span i18n>- Identify potential upgrade opportunities with existing customers and generate new sales</span>
      <span i18n>- Become a product expert of SuiteClerks's software solutions and your target market</span>

      <h1 i18n>Aptitudes</h1>
      <span i18n>- 1+ years sales experience</span>
      <span i18n>- Fluent in Chinese and English (written and verbal)</span>
      <span i18n>- Experience with cold calling to gain new business</span>
      <span i18n>- Experience working with sales revenue targets and KPI’s</span>
      <span i18n>- Can-do attitude, resilience to overcome objections and motivation for a high volume fast paced sales environment</span>
      <span i18n>- Nice to have: industry experience within one of the following areas: SaaS, OTA/Wholesaler/Meta, Hotel Revenue Management, Channel Management or Hospitality</span>
    </mat-card-content>

  </mat-card>

</section>
