import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule }    from '@angular/common/http';

import { ResponsiveModule } from "ngx-responsive";

import { MaterialModule } from "./material.module";

import { CustomBreakPointsProvider } from "./providers/custom-break-points.provider"

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { TopNavigationBarComponent } from './top-navigation-bar/top-navigation-bar.component';
import { FooterComponent } from './footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PricingComponent } from './pricing/pricing.component';
import { PmsComponent } from './products/pms/pms.component';
import { BookingButtonComponent } from './products/booking-button/booking-button.component';
import { WebsiteDesignComponent } from './products/website-design/website-design.component';
import { StatisticsRevenueComponent } from './products/statistics-revenue/statistics-revenue.component';
import { WorkingScheduleComponent } from './products/working-schedule/working-schedule.component';
import { IntelligentPricingComponent } from './products/intelligent-pricing/intelligent-pricing.component';
import { HostelsComponent } from './hostels/hostels.component';
import { ContactComponent } from './contact/contact.component';
import { HiringComponent } from './hiring/hiring.component';
import { FaqComponent } from './faq/faq.component';
import { ChatComponent } from './chat/chat.component';

import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { RedsysComponent } from './redsys/redsys.component';
import { SubscribeComponent } from './subscribe/subscribe.component';

const config: SocketIoConfig = { url: 'https://www.suiteclerk.com/sc-chat', options: { reconnection: true } };

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TopNavigationBarComponent,
    FooterComponent,
    PmsComponent,
    PricingComponent,
    BookingButtonComponent,
    WebsiteDesignComponent,
    StatisticsRevenueComponent,
    WorkingScheduleComponent,
    IntelligentPricingComponent,
    HostelsComponent,
    ContactComponent,
    HiringComponent,
    FaqComponent,
    ChatComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    RedsysComponent,
    SubscribeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    ResponsiveModule.forRoot(),
    HttpClientModule,
    SocketIoModule.forRoot(config)
  ],
  providers: [CustomBreakPointsProvider],
  bootstrap: [AppComponent]
})
export class AppModule { }
