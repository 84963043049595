import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-statistics-revenue',
  templateUrl: './statistics-revenue.component.html',
  styleUrls: ['./statistics-revenue.component.css']
})
export class StatisticsRevenueComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
