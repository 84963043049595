export const REDSYS_ERRORS = {
  "msg1": "Missing card data",
  "msg2": "Missing card number",
  "msg3": "Card number has to be numeric",
  "msg4": "Card number can not be negative",
  "msg5": "Missing expiry month",
  "msg6": "Expiry month has to be numeric",
  "msg7": "Invalid expiry year",
  "msg8": "Missing expiry year",
  "msg9": "Expiry year has to be numeric",
  "msg10": "Expiry year can not be negative",
  "msg11": "Invalid CVV length",
  "msg12": "CVV has to be numeric",
  "msg13": "CVV can not be negative",
  "msg14": "CVV is not necessary for this card",
  "msg15": "Invalid card number length",
  "msg16": "Invalid card number",
  "msg17": "Missing or invalid details"
}