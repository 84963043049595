<script>

</script>
<!-- Unified integration -->
<div id="card-form"></div>

<div *ngIf="errorMessage" class="alert-error">{{ errorMessage }}</div>

<!-- Operation ID and error code (if any) -->
<form name="datos">
  <input type="hidden" id="token" />
  <input type="hidden" id="errorCode" />
</form>